const config = {
  // Set the Demo Version
  demo: false,

  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  apiKey: "AIzaSyB0gdpCW1-tkpwbscCRmk7w1u5_3-OVlYY",
  authDomain: "learner-vht.firebaseapp.com",
  projectId: "learner-vht",
  storageBucket: "learner-vht.appspot.com",
  messagingSenderId: "583764187910",
  appId: "1:583764187910:web:f9a170f342fc2ec7769c87",
  measurementId: "G-XQHGED2SKD",

  // you get this type data from google adsense
  // <ins class="adsbygoogle"
  //    style="display:block"
  //    data-ad-client="xxxxxxxxxxxx"
  //    data-ad-slot="xxxxxxxxxxxxxx"
  //    data-ad-format="auto"
  //    data-full-width-responsive="true"></ins>
  // googleAdsense
  data_ad_client: "ca-pub-9667891148162497", //ca-pub-your-ad-client-id
  data_ad_slot: "9154149295", //your-ad-slot-id

  // Language Configurations
  // Get Your Language Codes ---> https://developers.google.com/admin-sdk/directory/v1/languages
  supportedLanguages: ["en", "hi", "ur"],
  defaultLanguage: "en",

  // If your Default Language is not in supportedLanguages then add it first and then set it as the defaultLanguage.

  // Quiz Configurations
  deductIncorrectAnswerScore: 1, // This will deduct the points if the user gives a wrong answer

  // Default country selection Configurations
  DefaultCountrySelectedInMobile: "in", // Default Country Selected in Mobile Login Screen

  // 1 vs 1 battle Quiz Configurations
  battlecorrectanswer: 4,
  randomBattleoneToTwoSeconds: 2, // Quick answer: 1 vs 1 battle points 2 added on the first 1 & 2 seconds
  randomBattlethreeToFourSeconds: 1, // Quick answer: 1 vs 1 battle points 1 added on the first 3 & 4 seconds
};

export default config;
